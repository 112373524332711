/* global I18n */

const BUTTON_SELECTOR = '[data-listing-id]'
const BUTTONS_CONTAINER = '.show-info__contact-details,.listing__wrapper'

class ListingRestrictions {
  static async init () {
    return new ListingRestrictions()
  }

  constructor () {
    this.getConfigAndMaybeDisableButtons()
  }

  getConfigAndMaybeDisableButtons () {
    const ids = $.map($(BUTTONS_CONTAINER).find(BUTTON_SELECTOR), (el) => el.getAttribute('data-listing-id'))
      .filter((value, index, currentValue) => currentValue.indexOf(value) === index)

    $.ajax({
      url: '/api/listing_restrictions',
      dataType: 'json',
      data: { ids: ids }
    }).done((data) => {
      $.each(data.restricted_lead_submissions, (_index, el) => {
        if (el[1]) {
          this.disableButtons(el[0])
        }
      })
    }).fail((error) => console.log(error))
  }

  disableButtons (id) {
    const buttons = $(BUTTONS_CONTAINER).find(`[data-listing-id=${id}]`)
    let backgroundColor

    $.each(buttons, (index, el) => {
      backgroundColor = $(el).css('background-color').replace(/\)/, ', 0.65);').replace('rgb(', 'background-color: rgba(')
      $(el).addClass('disabled').attr('disabled', true).attr('data-tooltip', I18n.t('this_seller_is_not_authorized')).attr('style', backgroundColor).removeAttr('title')
    })
  }
}

export default ListingRestrictions
