class ListingsCarousel {
  static async init (selector = '.listings-carousel') {
    $(selector).each((index, element) => {
      let margin = 30
      let stagePadding = 15

      if (['plain', 'gimt'].includes(element.id)) {
        margin = 0
        stagePadding = 0
      }

      const grids = parseInt($(element).data('grids-amount'))
      const isLoop = element.children.length >= grids
      const isSideNavigation = $('body').hasClass('side-navigation')

      $(element).on('initialized.owl.carousel ', function () {
        if (['idem'].includes(element.id)) {
          $('.owl-item', $(element)).filter('.cloned').find('.listing').each(function () {
            const $this = $(this)
            $('.nav-link', $this).each(function () {
              const $navLink = $(this)
              $navLink.attr('id', $navLink.attr('id') + '-cloned')
              $navLink.attr('aria-controls', $navLink.attr('aria-controls') + '-cloned')
              $navLink.attr('href', $navLink.attr('href') + '-cloned')
            })
            $('.tab-pane', $this).each(function () {
              const $tabPane = $(this)
              $tabPane.attr('id', $tabPane.attr('id') + '-cloned')
              $tabPane.attr('aria-labelledby', $tabPane.attr('aria-labelledby') + '-cloned')
            })
          })
        }

        const event = document.createEvent('Event')
        event.initEvent('listings.carousel.initialized', false, true)

        window.dispatchEvent(event)
      })

      const options = {
        lazyLoad: true,
        nav: true,
        loop: isLoop,
        autoplay: element.dataset.autoplay === 'true' && $(window).width() > 576,
        autoplayTimeout: 3000,
        smartSpeed: 250,
        autoHeight: true,
        autoplayHoverPause: true,
        navContainerClass: 'owl-nav notranslate',
        stagePadding: stagePadding,
        dots: false,
        margin: margin,
        responsive: {
          0: {
            items: 1
          },
          576: {
            items: 1
          },
          768: {
            items: 2
          },
          992: {
            items: isSideNavigation ? 2 : grids < 3 ? grids : 3
          },
          1200: {
            items: grids
          }
        }
      }

      // If the Norwich listings style is used
      if (['norvich', 'ownip'].includes(element.id)) {
        options.navText = [
          '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>',
          '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>'
        ]
      }

      // If there is a custom nav container
      if ($(element).data('nav')) {
        const navSelector = `[data-role="${$(element).data('nav')}"]`
        const $nav = $(navSelector)

        if ($nav.data('next') && $nav.data('prev')) {
          options.navText = [
            $nav.data('prev'),
            $nav.data('next')
          ]
          options.navContainer = navSelector
        }
      }

      $(element).owlCarousel(options)
    })
  }
}

export default ListingsCarousel
