import DescriptionToggle from 'listings/scripts/description_toggle'
import ListingGallery from 'listings/scripts/gallery'
import SimilarListings from 'listings/scripts/similar_listings'
import { ImagesGallery } from 'images_gallery/scripts/photoswipe_gallery'
import 'photoswipe/dist/default-skin/default-skin.png'
import 'photoswipe/dist/default-skin/preloader.gif'
$(function () {
  DescriptionToggle.init()
  ListingGallery.init()
  SimilarListings.init()
  ImagesGallery.init()
})
