import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/src/js/ui/photoswipe-ui-default.js'

class PhotoswipeGallery {
  static init (selector) {
    return new PhotoswipeGallery(selector)
  }

  constructor (selector) {
    const $images = $(selector)
    this.items = $images.map((_, img) => {
      const originalOrLarge = $(img).data('src')

      return { src: originalOrLarge || img.src }
    })

    let reIndex = null
    const primaryImageSource = $(`img[data-image-index='-1']`)

    this.items.each((index, item) => {
      item.w = 200
      item.h = 200

      if (primaryImageSource.length > 0) {
        // with image as primary
        let imageSource = null

        if (index === 0) {
          imageSource = primaryImageSource

          if (imageSource && imageSource[0]) {
            this.setImageSource(imageSource, item)
          }
        } else {
          imageSource = $(`img[data-image-index='${index - 1}']`)

          if (imageSource && imageSource[0]) {
            this.setImageSource(imageSource, item)
          }
        }
      } else {
        // with video as primary
        if (reIndex === null) {
          reIndex = $('[data-start-index]').data('start-index')
        }

        const imageSource = $(`img[data-image-index='${index + reIndex}']`)

        if (imageSource && imageSource[0]) {
          this.setImageSource(imageSource, item)
        } else {
          // images not in gallery
          const image = new Image()
          image.src = item.src
          image.onload = () => {
            item.w = image.naturalWidth
            item.h = image.naturalHeight
          }
        }
      }
    })
  }

  setImageSource (imageSource, item) {
    imageSource[0].onload = () => {
      this.setImageDimensions(item, imageSource)
    }

    this.setImageDimensions(item, imageSource)
  }

  setImageDimensions (item, imageSource) {
    const { naturalWidth, naturalHeight } = imageSource[0]

    if (imageSource[0].src !== item.src) {
      imageSource[0].src = $(imageSource[0]).data('src')
    }

    item.w = naturalWidth
    item.h = naturalHeight
  }

  appendPswpElement () {
    const element = `
      <div class='pswp' tabindex='-1' role='dialog' aria-hidden='true'>
        <div class='pswp__bg'></div>
        <div class='pswp__scroll-wrap'>
          <div class='pswp__container'>
            <div class='pswp__item'></div>
            <div class='pswp__item'></div>
            <div class='pswp__item'></div>
          </div>
          <div class='pswp__ui pswp__ui--hidden'>
          <div class='pswp__top-bar'>
            <div class='pswp__counter'></div>
              <button class='pswp__button pswp__button--zoom' title='Zoom in/out'></button>
              <button class='pswp__button pswp__button--close' title='Close (Esc)'></button>
              <div class='pswp__preloader'>
                <div class='pswp__preloader__icn'>
                  <div class='pswp__preloader__cut'>
                    <div class='pswp__preloader__donut'></div>
                  </div>
                </div>
              </div>
            </div>
            <div class='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
              <div class='pswp__share-tooltip'></div>
            </div>
            <button class='pswp__button pswp__button--arrow--left' title='Previous (arrow left)'></button>
            <button class='pswp__button pswp__button--arrow--right' title='Next (arrow right)'></button>
            <div class='pswp__caption'>
              <div class='pswp__caption__center'></div>
            </div>
        </div>
      </div>
    </div>`

    $('body').append(element)
  }

  open (url) {
    if (!this.pswpElement) {
      this.appendPswpElement()
      this.pswpElement = document.querySelector('.pswp')
    }

    const index = url ? this.items.map((_, item) => item.src).toArray().indexOf(url) : 0
    const options = {
      index: index,
      getDoubleTapZoom: (isMouseClick, item) => {
        if (isMouseClick) {
          return 2
        } else {
          return item.initialZoomLevel < 0.7 ? 1 : 2
        }
      }
    }
    const gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUIDefault, this.items, options)
    gallery.init()
  }
}

class ImagesGallery {
  static async init () {
    if ($('.custom-page__body :not(a) > img:not([data-no-gallery])').length) {
      const photoswipe = new PhotoswipeGallery('.custom-page__body :not(a) > img:not([data-no-gallery])')

      $('.custom-page__body :not(a) > img:not([data-no-gallery])').on('click', (e) => {
        photoswipe.open(e.target.src)
      })
    }
  }
}

export { ImagesGallery, PhotoswipeGallery }
