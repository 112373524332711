import Cookies from 'js-cookie'
import WebsiteConfigs from 'website_configs/website_configs'

const LISTING_PRICE_SELECTOR = '[data-price-selector="listing-price"][class!="price-added"]'

class ListingPrice {
  static async init () {
    const listingPrices = $(LISTING_PRICE_SELECTOR)

    $.each(listingPrices, (index, el) => {
      const availablePrices = JSON.parse(el.dataset.listingPrices)

      if (availablePrices?.length === 1 && typeof availablePrices[0] === 'string') {
        el.innerHTML = availablePrices[0]
      } else if (availablePrices?.length && availablePrices[0]) {
        const currency = (Cookies.get('currency') || WebsiteConfigs.currency).toUpperCase()

        const result = availablePrices.find(el => el.currency.toUpperCase() === currency)

        if (result) {
          el.innerHTML = result.display_price
        } else {
          const priceOnPrimaryCurrency = availablePrices.find(el => el.currency.toUpperCase() === WebsiteConfigs.currency.toUpperCase())
          el.innerHTML = priceOnPrimaryCurrency.display_price
        }

        $(el).addClass('price-added')
      }
    })
  }
}

export default ListingPrice
