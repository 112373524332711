import { PhotoswipeGallery } from 'images_gallery/scripts/photoswipe_gallery'

const COOKIE_CATEGORY = 'marketing'

class ListingGallery {
  static async init () {
    if ($('#gallery .gallery__item').length) {
      return new ListingGallery()
    }
  }

  constructor () {
    this.$galleryItem = $('#gallery .gallery__item')
    this.$thumbnailcarousel = $('#galleryThumbnailCarousel')
    this.$galleryThumbs = $('.gallery__thumbnail')
    this.$thumbnailContainer = $('.gallery__thumbnail-container')
    this.$scrollWrapper = $('.gallery__thumbnail-scroll')
    this.$prevThumbnailButton = $('.gallery__thumbnail-nav--prev')
    this.$nextThumbnailButton = $('.gallery__thumbnail-nav--next')

    this.imageCount = this.$galleryThumbs.length
    this.$previousButton = $('.gallery__nav--prev')
    this.$nextButton = $('.gallery__nav--next')
    this.currentIndex = -1
    this.loop = this.$previousButton.hasClass('loop')

    const cookiesConsent = window.orejime && window.orejime.internals.manager.consents[COOKIE_CATEGORY] !== true
    this.videoSrc = cookiesConsent ? 'data-src' : 'src'

    this.bindEvents()
    this.initThumbnailCarousel()
    this.initHorizontalScrolling()
  }

  bindEvents () {
    this.$previousButton.on('click', () => this.decrementPage())
    this.$nextButton.on('click', () => this.incrementPage())

    if (this.$galleryItem.length !== 0) {
      this.$galleryThumbs.each((index, galleryThumb) => {
        $(galleryThumb).on('click', (e) => { this.galleryImageFocus(e) })
      })
    }

    this.photoswipe = new PhotoswipeGallery('.gallery-image')

    $('.gallery__item img').on('click', () => this.photoswipe.open())
  }

  initHorizontalScrolling () {
    const scrollWrapper = this.$scrollWrapper[0]
    if (!scrollWrapper) return

    let isDragging = false
    let startX = 0
    let scrollStartLeft = 0

    const disableBodyScroll = (e) => {
      if (this.$scrollWrapper[0].contains(e.target)) {
        e.preventDefault()
      }
    }

    document.addEventListener('touchmove', disableBodyScroll, { passive: false })

    const handleTouchStart = (e) => {
      isDragging = true
      startX = e.touches[0].pageX
      scrollStartLeft = scrollWrapper.scrollLeft
    }

    const handleTouchMove = (e) => {
      if (!isDragging) return
      const deltaX = e.touches[0].pageX - startX
      scrollWrapper.scrollLeft = scrollStartLeft - deltaX
    }

    const handleTouchEnd = () => {
      isDragging = false
    }

    const handleWheel = (e) => {
      e.preventDefault()

      const delta = e.originalEvent.deltaX || e.originalEvent.deltaY

      scrollWrapper.scrollLeft += delta
    }

    this.$scrollWrapper.on('wheel', handleWheel)
    this.$scrollWrapper.on('touchstart', handleTouchStart)
    this.$scrollWrapper.on('touchmove', handleTouchMove)
    this.$scrollWrapper.on('touchend touchcancel', handleTouchEnd)
  }

  incrementPage () {
    if (this.currentIndex !== this.imageCount - 2) {
      this.currentIndex++
    } else if (this.loop) {
      this.currentIndex = -1
    }

    this.handlePageChange()
  }

  decrementPage () {
    if (this.currentIndex !== -1) {
      this.currentIndex--
    } else if (this.loop) {
      this.currentIndex = this.imageCount - 2
    }

    this.handlePageChange()
  }

  handlePageChange () {
    const page = this.$galleryThumbs.parent().find(`li[data-index='${this.currentIndex}']`)
    this.galleryImageFocus(page)
  }

  maybeDisplayButtons () {
    const lastImageIndex = this.imageCount - 2

    if (this.currentIndex === -1) { this.$previousButton.hide() }
    if (this.currentIndex > -1) { this.$previousButton.css('display', 'flex') }
    if (this.currentIndex === lastImageIndex) { this.$nextButton.hide() }
    if (this.currentIndex < lastImageIndex) { this.$nextButton.css('display', 'flex') }
  }

  galleryImageFocus (e) {
    const $target = $(e.currentTarget || e)
    const dataUrl = $target.data('src')
    const index = $target.data('index')
    const type = $target.data('type')
    const provider = $target.data('provider')
    this.currentIndex = index

    let $item = $(`#gallery .gallery__item [data-index="${index}"]`)

    if (dataUrl && $item.length === 0) {
      const isMuted = $target.data('muted')
      const mutedString = isMuted ? 'muted="false"' : ''

      if (type === 'video') {
        if (provider === 'native') {
          $item = $(`<video src="${dataUrl}" class="img-fluid" ${mutedString} data-index="${index}" controls></video>`)
        } else if (provider === 'youtube') {
          $item = $(`<iframe ${this.videoSrc}="${dataUrl}?version=3&enablejsapi=1&mute=${isMuted}" data-name="${COOKIE_CATEGORY}"  width="100%" height="270px" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=true/>`)
        } else if (provider === 'vimeo') {
          $item = $(`<iframe ${this.videoSrc}="${dataUrl}?muted=${isMuted}" data-name="${COOKIE_CATEGORY}" width="100%" height="270px" frameborder="0" allow="autoplay;" allowfullscreen=true/>`)
        }
      } else {
        $item = $(`<img src="${dataUrl}" class="img-fluid gallery-image" data-index="${index}">`)
        $item.on('click', (e) => this.photoswipe.open(dataUrl))
      }

      this.$galleryItem.append($item)
    }

    this.$galleryThumbs.each((index, galleryThumb) => {
      $(galleryThumb).removeClass('current')
    })

    this.$galleryItem.children().each((_index, item) => {
      $(item).addClass('hidden')

      if (item.tagName === 'VIDEO') {
        item.pause()
      } else if (item.tagName === 'IFRAME') {
        item.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
      }
    })

    $item.removeClass('hidden')
    $target.addClass('current')

    this.scrollToCurrentThumbnail($target)

    if (!this.loop) { this.maybeDisplayButtons() }
  }

  initThumbnailCarousel () {
    const isMobile = window.matchMedia('(max-width: 992px)').matches

    if (!this.$thumbnailcarousel.length) {
      return
    }

    const scrollStep = this.$scrollWrapper.width() - this.$galleryThumbs.width()

    if (!isMobile) {
      this.$prevThumbnailButton.on('click', () => {
        this.$scrollWrapper.css('scroll-behavior', 'smooth')
        this.$scrollWrapper.scrollLeft(this.$scrollWrapper.scrollLeft() - scrollStep)
        setTimeout(() => this.$scrollWrapper.css('scroll-behavior', 'auto'), 300)
      })

      this.$nextThumbnailButton.on('click', () => {
        this.$scrollWrapper.css('scroll-behavior', 'smooth')
        this.$scrollWrapper.scrollLeft(this.$scrollWrapper.scrollLeft() + scrollStep)
        setTimeout(() => this.$scrollWrapper.css('scroll-behavior', 'auto'), 300)
      })
    } else {
      this.$prevThumbnailButton.addClass('d-none')
      this.$nextThumbnailButton.addClass('d-none')
    }

    const updateArrows = () => {
      const scrollLeft = Math.ceil(this.$scrollWrapper.scrollLeft())
      const maxScroll = Math.floor(this.$thumbnailContainer[0].scrollWidth - this.$scrollWrapper.width())

      this.$prevThumbnailButton.toggleClass('hidden', scrollLeft <= 0)
      this.$nextThumbnailButton.toggleClass('hidden', scrollLeft >= maxScroll)
    }

    const hideNavIfNotScrollable = () => {
      const containerWidth = this.$scrollWrapper.width()
      const contentWidth = this.$thumbnailContainer[0].scrollWidth

      if (contentWidth <= containerWidth) {
        this.$prevThumbnailButton.addClass('hidden')
        this.$nextThumbnailButton.addClass('hidden')
        this.$thumbnailcarousel.addClass('p-0')
      }
    }

    this.$scrollWrapper.on('scroll', updateArrows)
    $(window).on('resize', () => {
      updateArrows()
      hideNavIfNotScrollable()
    })

    updateArrows()
    hideNavIfNotScrollable()
  }

  scrollToCurrentThumbnail ($thumbnail) {
    if (!$thumbnail || !$thumbnail.length || !this.$scrollWrapper.length) {
      return
    }

    const container = this.$scrollWrapper[0]
    const containerRect = container.getBoundingClientRect()
    const thumbnailRect = $thumbnail[0].getBoundingClientRect()

    if (thumbnailRect.left < containerRect.left || thumbnailRect.right > containerRect.right) {
      const offset = thumbnailRect.left < containerRect.left
        ? thumbnailRect.left - containerRect.left
        : thumbnailRect.right - containerRect.right

      this.$scrollWrapper.scrollLeft(this.$scrollWrapper.scrollLeft() + offset)
    }
  }
}

export default ListingGallery
