class WebsiteConfigs {
  static configs () {
    return $('code[data-configs]').data('configs')
  }

  static additionalConfigs () {
    return $('code[data-additional-configs]').data('additional-configs')
  }

  static get currency () {
    return this.configs().currency
  }

  static get convertPrices () {
    return this.configs().convert_prices
  }

  static get hideCurrencyIsoCode () {
    return this.configs().hide_currency_iso_code
  }

  static get gdprCompliance () {
    return this.configs().gdpr_compliance
  }

  static get showListingQuantity () {
    return this.configs().enable_listing_quantity
  }

  static get showWebshopStripeInvoiceCheckoutHint () {
    return this.configs().show_webshop_stripe_invoice_checkout_hint
  }

  static get allowOffer () {
    return this.configs().allow_offer
  }

  static get termsOfSalesPath () {
    return this.configs().terms_of_sales_path
  }

  static get automatedLocale () {
    return this.configs().automated_locale
  }

  static get manualLanguages () {
    return this.configs().manual_languages
  }

  static get primaryLanguageCode () {
    return this.configs().primary_language_code
  }

  static get controllerName () {
    return this.configs().controller_name
  }

  static get privatePriceWebshop () {
    return this.configs().private_price_webshop
  }

  static get contactFormAttachFilesLimit () {
    if (this.additionalConfigs()) {
      const customAttachFilesLimit = this.additionalConfigs().contact_form_attach_files_limit
      if (customAttachFilesLimit) return customAttachFilesLimit
    }

    return this.configs().contact_form_attach_files_limit
  }

  static get createWebshopLead () {
    return this.configs().create_webshop_lead
  }

  static get displayCookiesConsent () {
    return this.configs().display_cookies_consent
  }

  static get privacyPolicyPage () {
    return this.configs().privacy_policy_page
  }

  static get submitOrderButtonText () {
    return this.configs().submit_order_button_text
  }

  static get shoppingCartHeaderTitle () {
    return this.configs().shopping_cart_header_title
  }

  static get restrictLeadSubmissionByBuyerLocation () {
    return this.configs().restrict_lead_submission_by_buyer_location
  }

  static get customNewsletterButtonTitle () {
    return this.configs().custom_newsletter_button_title
  }
}

export default WebsiteConfigs
