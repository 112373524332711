import 'owl.carousel'
import ListingsCarousel from 'listings/scripts/carousel'
import ListingPrice from 'listings/scripts/listing_price'
import ListingRestrictions from 'listings/scripts/listing_restrictions'
import WebsiteConfigs from 'website_configs/website_configs'

class SimilarListings {
  static async init () {
    return new SimilarListings()
  }

  constructor () {
    this.rendered = false
    this.isLoading = false

    const renderedSimilarListings = $('.rendered-similar-listings')
    this.similarListingsElement = document.getElementById('js-similar-listings')

    if (renderedSimilarListings.length === 0 && this.similarListingsElement) {
      this.startObserving()
    }
  }

  startObserving () {
    if (!('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
      this.loadListings()
    } else {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!this.rendered) {
            this.loadListings()
          }
        }
      })

      observer.observe(this.similarListingsElement)
    }
  }

  loadListings () {
    if (this.rendered) return
    if (this.isLoading) return

    this.isLoading = true

    $.ajax({
      url: location.pathname + '/similar_listings',
      dataType: 'json'
    }).done((data) => {
      let element = $('#js-similar-listings')
      element.removeAttr('align')
      element.html(data.html)

      ListingsCarousel.init('#js-similar-listings .listings-carousel')
      ListingPrice.init()

      if (WebsiteConfigs.restrictLeadSubmissionByBuyerLocation) {
        ListingRestrictions.init()
      }

      window.lazyLoadInstance.update()
    }).fail((error) => {
      console.log(error)
    }).always(() => {
      this.isLoading = false
      this.rendered = true
    })
  }
}

export default SimilarListings
