class DescriptionToggle {
  static async init () {
    // Only display 'show all' button if there's overflow
    if ($('#description-toggle').length) {
      var description = $('.show-info__section-text')[0]
      if (description.scrollHeight - description.clientHeight > 3) {
        $('.show-info__description-toggle__wrapper').fadeIn(100)
      }
    }

    $('#description-toggle').click((e) => {
      e.preventDefault()
      $('.show-info__section-text').addClass('show-info__section-text--full')
      $(e.currentTarget).remove()
    })

    // Additional prices toggle - Need to relocate after !!!
    $('.with-additional-prices').on('click', function () {
      const $additionalPrices = $(this).siblings('.additional-prices')

      $(this).toggleClass('rotated')
      $additionalPrices.slideToggle(300)
    })
  }
}

export default DescriptionToggle
